import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-flex-column" }
const _hoisted_2 = { class: "v-interaction-header-container sp-interaction-details" }
const _hoisted_3 = { class: "v-header-left" }
const _hoisted_4 = { class: "v-interaction-title" }
const _hoisted_5 = { class: "v-care-plan-review-item-value" }
const _hoisted_6 = { class: "v-careplan-edit-item" }
const _hoisted_7 = { class: "v-careplan-edit-item-title" }
const _hoisted_8 = { class: "v-careplan-edit-item-content" }
const _hoisted_9 = { class: "v-careplan-edit-item" }
const _hoisted_10 = { class: "v-careplan-edit-item-title" }
const _hoisted_11 = { class: "v-careplan-edit-item-content v-level-of-need" }
const _hoisted_12 = { class: "v-careplan-edit-item" }
const _hoisted_13 = { class: "v-careplan-edit-item-title" }
const _hoisted_14 = { class: "v-careplan-edit-item-content" }
const _hoisted_15 = { class: "v-careplan-edit-item" }
const _hoisted_16 = { class: "v-careplan-edit-item-title" }
const _hoisted_17 = { class: "v-careplan-edit-item-content" }
const _hoisted_18 = { class: "v-careplan-edit-item" }
const _hoisted_19 = { class: "v-careplan-edit-item-title" }
const _hoisted_20 = { class: "v-careplan-edit-item-content" }
const _hoisted_21 = { class: "v-main-text" }

import {
  computed, onBeforeMount, onMounted, PropType,
  reactive,
  ref,
} from 'vue';
import { storeToRefs } from 'pinia';
import { Need } from '@/timeline/types/Need';
import useUserStore from '@/_shared/store/user';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import use from '@/_shared/compositionApi';
import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseInput from '@/_shared/components/inputs/BaseInput.vue';
import BaseSelect from '@/_shared/components/inputs/BaseSelect.vue';
import useNeedsStore from '@/_shared/store/needs';
import { carerStore } from '@/_shared/store/carers';
import BaseAlert from '@/_shared/components/feedback/BaseAlert.vue';
import { Person } from '@/_shared/store/people';
import NextReviewDue from '../NextReviewDue.vue';
import EditRiskAssessments from './EditRiskAssessments.vue';
import EditReview from './EditReview.vue';
import EditProvidedServices from './EditProvidedServices.vue';
import {
  buildNeedToEdit,
  NextReviewDateValidationAlerts, ReviewValidationErrors, validateNeed,
} from './edit-utils';
import { calculateLevelOfNeedClass, getResponsiblePeople } from '../utils';
import InteractionStateButtons from '../../InteractionStateButtons.vue';
import QuickActions from '../../QuickActions.vue';
import InteractionAssignCarers from '../../InteractionAssignCarers.vue';
import CarePlanPerson from '../CarePlanPerson.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'EditCarePlan',
  props: {
  theNeed: {
    type: Object as PropType<Need>,
    required: true,
  },
  reviewId: {
    type: Number,
    default: null,
  },
},
  emits: ['cancel', 'saved'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();

const props = __props;

const currentInteractionStore = useCurrentInteractionStore();
const { setCurrentInteraction, setNewNeedInteraction } = currentInteractionStore;
const { currentInteraction: reviewToEdit, iconUrl } = storeToRefs(currentInteractionStore);

const { getProvidedServicesForNeed, save } = useNeedsStore();

const { currentUser } = storeToRefs(useUserStore());

const providedServices = computed(() => getProvidedServicesForNeed(need.value, props.reviewId));

const providedServicesOptions = computed(() => providedServices.value?.map((v) => ({ value: v.id, text: v.name })).sort((a, b) => a.text.localeCompare(b.text)));

const levelOfNeedOptions = computed(() => need.value.levelOfNeedOptions.map((o) => ({ value: o[1]!, text: o[0] })));

const carerOptions = computed(() => carerStore.active(need.value.organisationUnitId).value.map((v) => ({
  value: v.id,
  text: v.name,
})));

const emit = __emit;

const need = ref<Need>(reactive(buildNeedToEdit(props.theNeed)));

const saveErrors = ref<ReviewValidationErrors>(null);
const alertText = ref<NextReviewDateValidationAlerts>(null);

const assignCarersModalVisible = ref(false);

const saveNeed = async () => {
  const { errors, alert } = validateNeed(need.value, reviewToEdit.value);
  if (!errors && !alert) {
    need.value.review = reviewToEdit.value;
    await save(need.value);
    emit('saved');
  } else {
    saveErrors.value = errors ?? [];
    alertText.value = alert ?? null;
  }
};

const confirmReviewDateAlert = async () => {
  alertText.value = null;
  need.value.review = reviewToEdit.value;
  await save(need.value);
  emit('saved');
};

const responsiblePersonIds = computed(() => {
  const ids = Array.from(reviewToEdit.value?.responsiblePersonIds || []);
  if (ids.length === 0 && currentUser.value.role !== 'informal_carer') {
    ids.push(currentUser.value.id);
  }
  return ids;
});

const responsiblePersons = computed<Person[]>(() => getResponsiblePeople(responsiblePersonIds.value));

function updateResponsibleIds(newValue: number[]) {
  if (!reviewToEdit.value) return;
  reviewToEdit.value.responsiblePersonIds = newValue;
}

onBeforeMount(async () => {
  carerStore.initialize();
});

const reviewToEditLoaded = ref(false);

onMounted(async () => {
  const currentReview = need.value.reviews.find((r) => r.state === 'planned');
  if (currentReview) {
    await setCurrentInteraction(currentReview.id);
  } else {
    await setNewNeedInteraction(need.value.clientId, {
      service_codename: 'careplan_review',
      organisation_unit_id: need.value.organisationUnitId.toString(),
      need_id: need.value.id.toString(),
      responsible_person_ids: [currentUser.value.id.toString()],
    });
  }

  reviewToEditLoaded.value = true;
  reviewToEdit.value.notePublic = '';
  reviewToEdit.value.finishAt = new Date().toString();
  reviewToEdit.value.state = 'closed';
  if (!reviewToEdit.value.responsiblePersonIds.length) {
    reviewToEdit.value.responsiblePersonIds.push(currentUser.value.id);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(BaseCard, {
      headerBackground: true,
      padding: false,
      contentTopBorder: false
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(BaseIcon, {
                name: "ncp-careplan",
                size: "2x",
                noHover: "",
                compact: "",
                backgroundColor: "black",
                foregroundColor: "white",
                foregroundHoverColor: "white",
                class: "v-service-icon"
              }),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(BaseInput, {
                  border: true,
                  modelValue: need.value.title,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((need.value.title) = $event))
                }, null, 8, ["modelValue"])
              ])
            ])
          ])
        ])
      ]),
      content: _withCtx(() => [
        _createVNode(NextReviewDue, {
          title: _unref(translate)('need.next_review_on'),
          value: need.value.nextReviewOn,
          showActionButton: true,
          fallbackText: _unref(translate)('need.next_review_not_set'),
          actionText: _unref(translate)('need.cancel_update'),
          onAction: _cache[1] || (_cache[1] = ($event: any) => (emit('cancel')))
        }, null, 8, ["title", "value", "fallbackText", "actionText"]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(translate)('need.identified_need')), 1),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(BaseInput, {
                border: true,
                textarea: true,
                modelValue: need.value.identifiedNeed,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((need.value.identifiedNeed) = $event)),
                placeholder: _unref(translate)('need.placeholders.identified_need_placeholder')
              }, null, 8, ["modelValue", "placeholder"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(translate)('need.level_of_need')), 1),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(BaseSelect, {
              border: true,
              boldLabel: "",
              labelWidth: "300px",
              options: levelOfNeedOptions.value,
              modelValue: need.value.levelOfNeed,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((need.value.levelOfNeed) = $event))
            }, {
              singlelabel: _withCtx(({ value: option }) => [
                _createElementVNode("span", {
                  class: _normalizeClass([`v-level-of-need-icon--${_unref(calculateLevelOfNeedClass)(option.value)}`, "v-level-of-need-icon"])
                }, _toDisplayString(option.value || 'NA'), 3),
                _createTextVNode(" " + _toDisplayString(option.label), 1)
              ]),
              option: _withCtx(({ option }) => [
                _createElementVNode("span", {
                  class: _normalizeClass([`v-level-of-need-icon--${_unref(calculateLevelOfNeedClass)(option.value)}`, "v-level-of-need-icon"])
                }, _toDisplayString(option.value || 'NA'), 3),
                _createTextVNode(" " + _toDisplayString(option.label), 1)
              ]),
              _: 1
            }, 8, ["options", "modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(translate)('need.planned_outcomes')), 1),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(BaseInput, {
              border: true,
              textarea: true,
              modelValue: need.value.plannedOutcomes,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((need.value.plannedOutcomes) = $event)),
              placeholder: _unref(translate)('need.placeholders.planned_outcomes_placeholder')
            }, null, 8, ["modelValue", "placeholder"])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(translate)('need.how_to_achive_outcomes')), 1),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(BaseInput, {
              border: true,
              textarea: true,
              modelValue: need.value.howToAchiveOutcomes,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((need.value.howToAchiveOutcomes) = $event)),
              placeholder: _unref(translate)('need.placeholders.how_to_achieve_outcomes_placeholder')
            }, null, 8, ["modelValue", "placeholder"])
          ])
        ]),
        _createVNode(EditProvidedServices, {
          items: need.value.needProvidedServicesAttributes,
          options: providedServicesOptions.value
        }, null, 8, ["items", "options"]),
        _createVNode(EditRiskAssessments, {
          items: need.value.riskAssessmentsAttributes
        }, null, 8, ["items"]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(translate)('interaction.responsiblePeople')), 1),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(BaseSelect, {
              border: true,
              boldLabel: "",
              labelWidth: "300px",
              options: carerOptions.value,
              modelValue: need.value.responsiblePersonIds,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((need.value.responsiblePersonIds) = $event)),
              multiple: true
            }, null, 8, ["options", "modelValue"])
          ])
        ]),
        _createVNode(EditReview, {
          review: _unref(reviewToEdit),
          need: need.value,
          onCancel: _cache[7] || (_cache[7] = ($event: any) => (emit('cancel'))),
          errors: saveErrors.value
        }, null, 8, ["review", "need", "errors"]),
        (reviewToEditLoaded.value)
          ? (_openBlock(), _createBlock(QuickActions, {
              key: 0,
              onShowAssignCarersModal: _cache[8] || (_cache[8] = ($event: any) => (assignCarersModalVisible.value = true))
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(responsiblePersons.value, (person) => {
          return (_openBlock(), _createBlock(CarePlanPerson, {
            key: person.id,
            person: person,
            class: "v-careplan-edit-item-person"
          }, null, 8, ["person"]))
        }), 128)),
        _createVNode(InteractionStateButtons, {
          isEditing: false,
          onSaveInteraction: saveNeed
        })
      ]),
      _: 1
    }),
    (alertText.value)
      ? (_openBlock(), _createBlock(BaseAlert, {
          key: 0,
          class: "v-integration-nhs-token-alert",
          dangerAlert: false,
          icon: "info",
          onConfirmAlert: confirmReviewDateAlert,
          onCancelAlert: _cache[9] || (_cache[9] = () => alertText.value = null),
          confirmText: _unref(translate)('alert.continue')
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(translate)('need.alerts.name_save_title')), 1)
          ]),
          body: _withCtx(() => [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("span", null, _toDisplayString(_unref(translate)(`need.alerts.${alertText.value}`)), 1)
            ])
          ]),
          _: 1
        }, 8, ["confirmText"]))
      : _createCommentVNode("", true),
    (reviewToEditLoaded.value)
      ? (_openBlock(), _createBlock(InteractionAssignCarers, {
          key: 1,
          onCloseAssignCarers: _cache[10] || (_cache[10] = ($event: any) => (assignCarersModalVisible.value = false)),
          interaction: _unref(reviewToEdit),
          selectedEntries: responsiblePersonIds.value,
          onUpdateResponsibleIds: updateResponsibleIds,
          showModal: assignCarersModalVisible.value,
          iconUrl: _unref(iconUrl)
        }, null, 8, ["interaction", "selectedEntries", "showModal", "iconUrl"]))
      : _createCommentVNode("", true)
  ]))
}
}

})